import {AuthenticationService} from "./AuthenticationService";
import axios from "axios";
import {ApplicationPath} from "../class/general/ApplicationPath";
import {AdaUser} from "../class/user/AdaUser";
import orderBy from 'lodash/orderBy'

const PILOT_ACCESS_TABLE: string = 'pilotAccess'
const ADA_USER_TABLE: string = 'adaUser'
const ADAUSER_LINK_TABLE: string = 'adaUser_link'


const devAhsIds = {
    'danydh@arteveldehs.be': 2000000650,
    'barewe@arteveldehs.be': 2000002150
}
const isLocalHost = window.location.hostname === "localhost"


export class AdaUserService {

    authenticationInstance: AuthenticationService
    applicationPath: ApplicationPath
    adaUserLookup: object = {}
    adaUserLinkLookup: object = {}
    adaUserLinkForAdaUserLookup: object = {}
    pilotAccess: any[]

    constructor(authenticationInstance, applicationPath) {
        this.authenticationInstance = authenticationInstance
        this.applicationPath = applicationPath
    }

    getLoggedInAdaUser = () => {
        return new Promise((resolve, reject) => {

            const url = `${this.applicationPath.auth_api()}getLoggedInAdaUser/`
            axios.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error('GET GETLOGINADAUSER ERROR', error)
                    reject(error)
                })
        })
    }

    loginAhs = () => {
        //https://www.arteveldehogeschool.be/ada/ada-server/loginLink.php
        const url = `${this.applicationPath.ada_server()}loginLink.php?reddata=${this.applicationPath.mode}`
        window.location.replace(url)
    }

    logoutAhs = () => {
        //https://www.arteveldehogeschool.be/ada/ada-server/loginLink.php
        const url = `${this.applicationPath.ada_server()}logOff.php`
        window.location.replace(url)
    }

    //SINGLE USER
    getAdaUser = (id: number) => {
        return new Promise((resolve, reject) => {

            if (this.adaUserLookup[id]) {
                resolve(this.adaUserLookup[id])
                return
            }

            axios.get(`${this.applicationPath.crud_api_v2()}${ADA_USER_TABLE}/${id}`, this.authenticationInstance.axiosOptions)
                .then(response => {
                    //V2 OK!
                    const adaUser = response.data
                    //dev:
                    if (isLocalHost) adaUser.arteveldeInternId = devAhsIds[adaUser.email]

                    this.adaUserLookup[adaUser.id] = adaUser
                    resolve(adaUser)
                })
                .catch(error => {
                    console.error('GET ADA USER ERROR', error)
                    reject(error)
                })
        })
    }

    //SINGLE USER
    getAdaUserFromAhsUser = (ahsUser: any) => {
        return new Promise((resolve, reject) => {

            const url = `${this.applicationPath.crud_api_v2()}${ADA_USER_TABLE}/?transform=1&filter[]=email,eq,${ahsUser.Upn}`

            axios.get(url, this.authenticationInstance.axiosOptions)
                .then(response => {

                    console.log('getAdaUserFromAhsUser', response)
                    const adaUsers = response.data.records

                    //wanneer de user niet in onze tabel zit, aanmaken!
                    if (adaUsers.length > 0) {
                        const adaUser = adaUsers[0]
                        resolve(adaUser)
                    } else {
                        const au = new AdaUser()
                        au.voornaam = ahsUser.Voornaam
                        au.familienaam = ahsUser.Naam
                        au.email = ahsUser.Upn
                        au.profielfoto = `https://lib.arteveldehogeschool.be/mediatheken/dev/foto/foto.php?size=M&user=${ahsUser.Upn}`
                        this.createAdaUser(au).then(res => resolve(au))
                    }
                })
                .catch(error => {
                    console.error('GET ADA USER ERROR', error)
                    reject(error)
                })
        })
    }

    //MULTIPLE USERS
    getAdaUsers = (ids: number[]) => {
        return new Promise((resolve, reject) => {

            if (ids.length === 0) {
                resolve([])
                return
            }
            const cachedUsers = ids.reduce((users, id) => {
                if (this.adaUserLookup[id]) users.push(this.adaUserLookup[id])
                return users
            }, [])

            const usersToFetch = ids.filter(id => !this.adaUserLookup[id])

            if (usersToFetch.length === 0) {
                resolve(cachedUsers)
                return
            }

            axios.get(`${this.applicationPath.crud_api_v2()}${ADA_USER_TABLE}/${usersToFetch.join(',')}?transform=1`, this.authenticationInstance.axiosOptions)
                .then(response => {

                    let adaUsers = response.data //.records
                    if (!Array.isArray(adaUsers)) adaUsers = [adaUsers]
                    for (const adaUser of adaUsers) {
                        if(adaUser.complete === 0 ) {
                            adaUser.voornaam = 'ZZZZZZZZZZZZZZZZZ'
                            adaUser.familienaam = 'ZZZZZZZZZZZZZZZZZ'
                        }
                        if(adaUser.profielfoto === null) {
                            adaUser.profielfoto = 'https://i.pravatar.cc/300'
                        }
                        this.adaUserLookup[adaUser.id] = adaUser
                    }
                    resolve(orderBy([...adaUsers, ...cachedUsers], [(u) => u.familienaam.toLowerCase(), (u) => u.voornaam.toLowerCase()]))
                    //resolve([...adaUsers, ...cachedUsers])
                })
                .catch(error => {
                    console.error('GET ADA USER ERROR', error)
                    reject(error)
                })
        })
    }

    getAdaUsersFromShortEmail = (emails: string) => {
        return new Promise((resolve, reject) => {

            const queryArr = emails.split(',').map((email, index) => {
                    return `filter${index + 1}=email,eq,${email.trim().toLowerCase()}`
            })

            const url = `${this.applicationPath.crud_api_v2()}${ADA_USER_TABLE}/?${queryArr.join('&')}&transform=1`

            axios.get(url, this.authenticationInstance.axiosOptions)
                .then(response => {
                    let adaUsers = response.data.records
                    for(const adaUser of adaUsers) {
                        if(!adaUser.familienaam) adaUser.familienaam = ''
                        if(!adaUser.voornaam) adaUser.voornaam = ''
                    }
                    resolve(orderBy(adaUsers, [(u) => u.familienaam.toLowerCase(), (u) => u.voornaam.toLowerCase()]))
                })
                .catch(error => {
                    console.error('GET ADA USER ERROR', error)
                    reject(error)
                })
        })
    }

    //MULTIPLE USERS
    getAllAdaUsers = (page: number = 1, itemsPerPage: number = 20, filter) => {
        return new Promise((resolve, reject) => {

            let url = `${this.applicationPath.crud_api_v2()}${ADA_USER_TABLE}/?order=familienaam&order=voornaam&page=${page},${itemsPerPage}&transform=1`
            if (filter) url += filter
            axios.get(url, this.authenticationInstance.axiosOptions)
                .then(response => {

                    let adaUsers = response.data
                    resolve(adaUsers)
                })
                .catch(error => {
                    console.error('GET ALL ADA USERS ERROR', error)
                    reject(error)
                })
        })
    }

    createAdaUser = (adaUser) => {

        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.crud_api_v2()}${ADA_USER_TABLE}/?transform=1`

            console.log('CREATING ADA USER', adaUser, url)
            axios.post(url, adaUser)
                .then((result) => {
                    console.log('RESULT', result)
                    adaUser.id = result.data
                    this.adaUserLookup[adaUser.id] = adaUser
                    resolve(adaUser)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    updateAdaUser = (adaUser) => {

        const url = `${this.applicationPath.crud_api_v2()}${ADA_USER_TABLE}/${adaUser.id}?transform=1`

        return new Promise((resolve, reject) => {
                axios.put(url, adaUser)
                    .then((result) => {
                        this.adaUserLookup[adaUser.id] = adaUser
                        resolve(result)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    searchAdaUsers = (query) => {
        return new Promise((resolve, reject) => {

            const url = `${this.applicationPath.ada_api()}/searchAdaUser/${query}`
            axios.get(url)
                .then(response => {
                    console.log('searchAdaUsers', response)
                    resolve(orderBy(response.data, [(u) => u.familienaam.toLowerCase(), (u) => u.voornaam.toLowerCase() , (u) => u.complete ]))
                })
                .catch(error => {
                    console.error('SEARCH ADA USER ERROR', error)
                    reject(error)
                })
        })
    }

    //ADD INCOMPLETE ADA-USER : RETURN ID

    addIncompleteAdaUser = (user) => {
        return new Promise ((resolve, reject) => {
            const url = `${this.applicationPath.ada_api()}/addIncompleteAdaUser/`
            const requestOptions = {
                method : "POST" ,
                body : JSON.stringify(user)
            }

            fetch(url , requestOptions)
                .then(response => resolve(response.json()))
                .catch(reject)
        })
    }
    
    //==============================================================
    //==============================================================
    //                      ADAUSER_LINK
    //==============================================================
    //==============================================================

    getAdaUserLinks = (ref, refId) => {
        return new Promise((resolve, reject) => {

            if (!this.adaUserLinkLookup[ref]) this.adaUserLinkLookup[ref] = {}

            if (this.adaUserLinkLookup[ref][refId]) {
                resolve(this.adaUserLinkLookup[ref][refId])
                return
            }

            axios.get(`${this.applicationPath.crud_api_v2()}${ADAUSER_LINK_TABLE}/?transform=1&filter[]=ref,eq,${ref}&filter[]=refId,eq,${refId}`, this.authenticationInstance.axiosOptions)
                .then(response => {
                    //console.warn('V2 REFACTOR PLEASE!!', response)

                    const adaUserLinks = response.data.records //[ADAUSER_LINK_TABLE]
                    this.adaUserLinkLookup[ref][refId] = adaUserLinks

                    //ada users ophalen
                    this.getAdaUsers(adaUserLinks.map(user => user.adaUser)).then(res => {
                        //de adaUser MOETEN nu in de adaUserService.adaUserLookup zitten
                        resolve(this.adaUserLinkLookup[ref][refId])
                    })

                })
                .catch(error => {
                    console.error('GET ADAUSER LINK ERROR', error)
                    reject(error)
                })
        })
    }

    getAdaUserLinksForAdaUser = (adaUser) => {
        return new Promise((resolve, reject) => {


            if (this.adaUserLinkForAdaUserLookup[adaUser.id]) {
                resolve(this.adaUserLinkForAdaUserLookup[adaUser.id])
                return
            }

            axios.get(`${this.applicationPath.crud_api_v2()}${ADAUSER_LINK_TABLE}/?transform=1&filter[]=adaUser,eq,${adaUser.id}`, this.authenticationInstance.axiosOptions)
                .then(response => {
                    //console.log('V2 REFACTOR PLEASE!!', response)

                    const adaUserLinks = response.data.records //[ADAUSER_LINK_TABLE]
                    this.adaUserLinkForAdaUserLookup[adaUser.id] = adaUserLinks
                    //this.adaUserLinkLookup[ref][refId] = adaUserLinks
                    resolve(adaUserLinks)

                })
                .catch(error => {
                    console.error('GET ADAUSER LINK ERROR', error)
                    reject(error)
                })
        })
    }

    createAdaUserLink = (adaUserLink) => {

        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.crud_api_v2()}${ADAUSER_LINK_TABLE}/?transform=1`

            axios.post(url, adaUserLink)
                .then((result) => {
                    adaUserLink.id = result.data
                    if (!this.adaUserLinkLookup[adaUserLink.ref]) this.adaUserLinkLookup[adaUserLink.ref] = {}
                    if (!this.adaUserLinkLookup[adaUserLink.ref][adaUserLink.refId]) this.adaUserLinkLookup[adaUserLink.ref][adaUserLink.refId] = []
                    this.adaUserLinkLookup[adaUserLink.ref][adaUserLink.refId].push(adaUserLink)
                    if (!this.adaUserLinkForAdaUserLookup[adaUserLink.adaUser]) this.adaUserLinkForAdaUserLookup[adaUserLink.adaUser] = []
                    this.adaUserLinkForAdaUserLookup[adaUserLink.adaUser].push(adaUserLink)

                    this.getAdaUser(adaUserLink.adaUser).then(au => {
                        resolve(this.adaUserLinkLookup[adaUserLink.ref][adaUserLink.refId])

                    })
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    updateAdaUserLink = (adaUserLink) => {

        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.crud_api_v2()}${ADAUSER_LINK_TABLE}/${adaUserLink.id}?transform=1`

            axios.put(url, adaUserLink)
                .then((result) => {
                    this.adaUserLinkLookup[adaUserLink.ref][adaUserLink.refId] = this.adaUserLinkLookup[adaUserLink.ref][adaUserLink.refId].filter(aul => aul.id !== adaUserLink.id)
                    resolve(this.adaUserLinkLookup[adaUserLink.ref][adaUserLink.refId])
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    deleteAdaUserLink = (adaUserLink) => {

        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.crud_api_v2()}${ADAUSER_LINK_TABLE}/${adaUserLink.id}?transform=1`

            axios.delete(url)
                .then((result) => {
                    this.adaUserLinkLookup[adaUserLink.ref][adaUserLink.refId] = this.adaUserLinkLookup[adaUserLink.ref][adaUserLink.refId].filter(aul => aul.id !== adaUserLink.id)
                    resolve(this.adaUserLinkLookup[adaUserLink.ref][adaUserLink.refId])
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    deleteAdaUserLinks = (provider) => {

        return new Promise((resolve, reject) => {

            if (!provider.class) throw(new Error('No class prop on provider'))

            const ids = this.adaUserLinkLookup[provider.class][provider.id].map(aul => aul.id).join(',')
            let url = `${this.applicationPath.crud_api_v2()}${ADAUSER_LINK_TABLE}/${ids}}?transform=1`

            axios.delete(url)
                .then((result) => {
                    delete this.adaUserLinkLookup[provider.class][provider.id]
                    resolve('Ada user links deleted')
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    //==============================================================
    //==============================================================
    //                      PILOT ACCESS
    //==============================================================
    //==============================================================


    getPilotAccess = () => {
        return new Promise((resolve, reject) => {

            const url = `${this.applicationPath.crud_api_v2()}${PILOT_ACCESS_TABLE}/?transform=1`
            axios.get(url)
                .then(response => {
                    console.log('V2 REFACTOR PLEASE!!', response)

                    this.pilotAccess = response.data[PILOT_ACCESS_TABLE]

                    this.getAdaUsers(this.pilotAccess.map(pa => pa.adaUser)).then(res => {
                        resolve(this.pilotAccess)

                    })
                })
                .catch(error => {
                    console.error('GET PILOT ACCESS ERROR', error)
                    reject(error)
                })
        })
    }

    createPilotAccess = (pilotAccess) => {

        return new Promise((resolve, reject) => {
            const url = `${this.applicationPath.crud_api_v2()}${PILOT_ACCESS_TABLE}/?transform=1`

            axios.post(url, pilotAccess)
                .then((result) => {
                    pilotAccess.id = result.data
                    if (!this.pilotAccess) this.pilotAccess = []
                    this.pilotAccess.push(pilotAccess)

                    this.getAdaUser(pilotAccess.adaUser).then(res => {
                        resolve(this.pilotAccess)
                    })
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    deletePilotAccess = (pilotAccess) => {

        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.crud_api_v2()}${PILOT_ACCESS_TABLE}/${pilotAccess.id}?transform=1`

            axios.delete(url)
                .then((result) => {
                    this.pilotAccess = this.pilotAccess.filter(pa => pa.id !== pilotAccess.id)
                    resolve(this.pilotAccess)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    //==============================================================
    //==============================================================
    //                      ADA USER BANNER
    //==============================================================
    //==============================================================

    uploadAdaUserBanner = (file, uuid, progressCallback) => {
        return new Promise((resolve, reject) => {

            const formData = new FormData()

            formData.append(file.path, file, uuid + '.jpg')

            const config = {
                onUploadProgress: progressCallback
            }

            console.group()
            const url = `${this.applicationPath.ada_api()}/uploadAdaUserBanner/${uuid}?transform=1`
            console.info('CALL uploadAdaUserBanner', url, formData, config)
            axios.post(url, formData, config)
                .then(response => {
                    console.log('SERVER RESPONSE', response)
                    resolve(response)
                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR uploadAdaUserBanner', error)
                    reject(error)
                });
        })
    }

    //==============================================================
    //==============================================================
    //                      PREFS
    //==============================================================
    //==============================================================

    getPref = (adaUser, pref) => {
        if (!adaUser.prefs) adaUser.prefs = ''
        if (adaUser.prefs === '') return null

        const pObj = JSON.parse(adaUser.prefs)
        if (pObj) return pObj[pref]
        return null
    }

    setPref = (adaUser, pref, value) => {
        if (!adaUser.prefs) adaUser.prefs = ''
        const prefs = adaUser.prefs === '' ? {} : JSON.parse(adaUser.prefs)
        prefs[pref] = value
        adaUser.prefs = JSON.stringify(prefs)
        this.updateAdaUser(adaUser)
    }

    //==============================================================
    //==============================================================
    //                      ROLES
    //==============================================================
    //==============================================================

    hasRol = (adaUser, rol) => {
        if (adaUser.roles === '') return false
        const roles = adaUser.roles.split(',')
        return roles.includes(rol)
    }

    toggleRol = (adaUser, rol) => {
        let roles = adaUser.roles === '' ? [] : adaUser.roles.split(',')
        if (roles.includes(rol)) {
            roles = roles.filter(r => r !== rol)
        } else {
            roles.push(rol)
        }
        adaUser.roles = roles.join(',')
        this.updateAdaUser(adaUser)
    }

}
